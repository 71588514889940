import React, { FC } from "react";
import { styled } from "@mui/system";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import useUserData from "../../../../hooks/useUserData";
import toast from "react-hot-toast";

interface Props{
  feedId: string;
  hashtags?: string[];
  text?: string;
}

export const ShareButtons: FC<Props> = ({ feedId, hashtags, text }) => {
  const userData = useUserData();
  const url =
  (process.env.NEXT_PUBLIC_APP_CONFIG === "prod" ? "https://candao.io/" : process.env.NEXT_PUBLIC_APP_CONFIG === "staging"
    ? "https://staging.candao.io/" : "https://dev.candao.io/") + `feed?referral=${encodeURIComponent(userData?.referralCode || '')}&feedId=${encodeURIComponent(feedId)}&postId=${encodeURIComponent(feedId)}`;
  
  const mailtoLink = `mailto:?body=${encodeURIComponent((text || '').concat('\n').concat(url))}`;

  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text || '')}`;

  const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text || '')}`
  
  const whatsappShareUrl = `https://wa.me/?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text || '')}`
  
  return (
    <div className="column flex justify-between">
      <span className="lg: display-none"
        style={{
          fontSize: "14px",
          color: "#4B5563",
          fontWeight: 500,
          marginTop: "4px",
          marginRight: "4px",
        }}
      >
        Share
      </span>
      <div className="row flex" style={{ gap: 8, overflow: "clip" }}>
        <div>
          <div className="column flex flex-wrap" style={{ gap: 8 }}>
            <StyledButton>
              <FacebookShareButton hashtag={hashtags && hashtags[0]} url={url}>
                <svg width="17" height="16" className="mt-1" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5901_9998)"><path d="M16.1499 8C16.1499 3.58206 12.5678 0 8.1499 0C3.73196 0 0.149902 3.58206 0.149902 8C0.149902 11.9927 3.07495 15.3025 6.90001 15.9032V10.313H4.86823V8H6.90001V6.2371C6.90001 4.23232 8.09478 3.12417 9.9218 3.12417C10.7971 3.12417 11.7128 3.28055 11.7128 3.28055V5.24933H10.7037C9.7103 5.24933 9.3998 5.86584 9.3998 6.49923V8H11.6183L11.264 10.313H9.3998V15.9032C13.2249 15.3036 16.1499 11.9938 16.1499 8Z" fill="#1977F3"/><path d="M11.2639 10.3129L11.6183 7.99985H9.39973V6.49908C9.39973 5.86682 9.70911 5.24918 10.7036 5.24918H11.7128V3.2804C11.7128 3.2804 10.797 3.12402 9.92174 3.12402C8.09471 3.12402 6.89995 4.23104 6.89995 6.23695V7.99985H4.86816V10.3129H6.89995V15.9031C7.3072 15.9672 7.72458 15.9998 8.14984 15.9998C8.5751 15.9998 8.99248 15.9661 9.39973 15.9031V10.3129H11.2639Z" fill="#FEFEFE"/></g><defs><clipPath id="clip0_5901_9998"><rect width="16" height="16" fill="white" transform="translate(0.149902)"/></clipPath></defs></svg>
              </FacebookShareButton>
            </StyledButton>
            <StyledButton>
              <a href={twitterShareUrl} target="_blank" rel="noopener noreferrer" className="flex justify-center">  
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5901_9800)"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.9722 16H2.52778C1.546 16 0.75 15.204 0.75 14.2222V1.77778C0.75 0.796 1.546 0 2.52778 0H14.9722C15.954 0 16.75 0.796 16.75 1.77778V14.2222C16.75 15.204 15.954 16 14.9722 16Z" fill="#212121"/><path d="M13.23 12.48H10.4067L4.27002 3.52002H7.09338L13.23 12.48ZM10.7431 11.7202H11.8672L6.75696 4.27983H5.63279L10.7431 11.7202Z" fill="white"/><path d="M5.15386 12.4802L8.27002 8.72262L7.86249 8.16016L4.27002 12.4802H5.15386Z" fill="white"/><path d="M8.90991 6.94777L9.31 7.52002L12.7499 3.52002H11.8657L8.90991 6.94777Z" fill="white"/></g><defs><clipPath id="clip0_5901_9800"><rect width="20" height="20" fill="white" transform="translate(0.75)"/></clipPath></defs></svg>
              </a>
            </StyledButton>
            <StyledButton>
              <a href = {mailtoLink} className="flex justify-center">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.34985 4.66683C2.34985 4.31321 2.49033 3.97407 2.74038 3.72402C2.99043 3.47397 3.32956 3.3335 3.68319 3.3335H13.0165C13.3701 3.3335 13.7093 3.47397 13.9593 3.72402C14.2094 3.97407 14.3499 4.31321 14.3499 4.66683V11.3335C14.3499 11.6871 14.2094 12.0263 13.9593 12.2763C13.7093 12.5264 13.3701 12.6668 13.0165 12.6668H3.68319C3.32956 12.6668 2.99043 12.5264 2.74038 12.2763C2.49033 12.0263 2.34985 11.6871 2.34985 11.3335V4.66683Z" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/><path d="M2.34985 4.6665L8.34985 8.6665L14.3499 4.6665" stroke="#6B7280" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/></svg>
              </a>
            </StyledButton>
            <StyledButton>
              <a href={telegramShareUrl} target="_blank" rel="noopener noreferrer" className="flex justify-center">  
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5901_9815)"><path d="M8.0498 16C12.4681 16 16.0498 12.4183 16.0498 8C16.0498 3.58172 12.4681 0 8.0498 0C3.63153 0 0.0498047 3.58172 0.0498047 8C0.0498047 12.4183 3.63153 16 8.0498 16Z" fill="url(#paint0_linear_5901_9815)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M3.67099 7.91547C6.00315 6.89938 7.55829 6.22952 8.33641 5.90587C10.5581 4.9818 11.0197 4.82128 11.3206 4.81597C11.3868 4.81481 11.5348 4.83121 11.6306 4.90899C11.7116 4.97466 11.7338 5.06337 11.7445 5.12563C11.7551 5.1879 11.7684 5.32974 11.7579 5.44057C11.6375 6.70556 11.1165 9.77535 10.8515 11.1922C10.7394 11.7917 10.5186 11.9927 10.3048 12.0123C9.84021 12.0551 9.48743 11.7053 9.03747 11.4104C8.33336 10.9488 7.93559 10.6615 7.25213 10.2111C6.46228 9.69061 6.97431 9.40454 7.42444 8.93701C7.54224 8.81465 9.58917 6.95282 9.62879 6.78392C9.63375 6.7628 9.63834 6.68406 9.59157 6.64248C9.54479 6.60091 9.47575 6.61512 9.42593 6.62643C9.35531 6.64246 8.2305 7.38592 6.05148 8.85681C5.73221 9.07605 5.44302 9.18287 5.18392 9.17727C4.89827 9.1711 4.34881 9.01577 3.94035 8.88299C3.43935 8.72013 3.04116 8.63403 3.07584 8.35745C3.0939 8.21339 3.29228 8.06607 3.67099 7.91547Z" fill="white"/></g><defs><linearGradient id="paint0_linear_5901_9815" x1="8.0498" y1="0" x2="8.0498" y2="15.8813" gradientUnits="userSpaceOnUse"><stop stop-color="#2AABEE"/><stop offset="1" stop-color="#229ED9"/></linearGradient><clipPath id="clip0_5901_9815"><rect width="16" height="16" fill="white" transform="translate(0.0498047)"/></clipPath></defs></svg>
              </a>
            </StyledButton>
            <StyledButton>
              <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer" className="flex justify-center">  
                <WhatsappIcon round size={20}/>
              </a>
            </StyledButton>
            <StyledButton>
              <LinkedinShareButton url={url}>
                <LinkedinIcon round size={20} className="mt-1" />
              </LinkedinShareButton>
            </StyledButton>
            <StyledButton className="flex justify-center items-center" onClick={() => { 
              navigator.clipboard.writeText(url);
              toast.success("Link copied to clipboard");
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-copy"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" /><path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" /></svg>
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export const StyledButton = styled("button")(() => ({
  width: "30px",
  border: "1px solid #E5E7EB",
  borderRadius: "8px",
  color: "#5850EC",
  fontWeight: "500",
  fontSize: "14px",
}));
